<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card class="mx-auto" max-width="600" outlined>
          <v-card-title class="card-title">
            <img alt="Logo" src="../assets/stru_logo.png"/>
            <v-card-subtitle><h1>Профиль<br>пользователя</h1></v-card-subtitle>
          </v-card-title>
          <v-card-text>
            <v-container v-if="!sent">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-text-field type="email" v-model="email" label="Введите свой адрес электронной почты" :rules="emailRules" required></v-text-field>
                </v-row>
                <v-row class="mt-2">На этот адрес будет отправлено письмо с уникальной ссылкой, пройдя по которой вы попадёте на страницу с информацией о пользователе.</v-row>
                <v-row class="mt-4">
                  <v-btn @click="sendMyLink">Получить уникальную ссылку</v-btn>
                </v-row>
              </v-form>
            </v-container>
            <v-container v-if="sent">
              <v-row>
                <p>На указанный адрес отправлено письмо, содержащее уникальную ссылку на страницу с информацией о пользователе.</p>
                <p>Это окно можно закрыть</p>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  components: {
  },
  data: function () {
    return {
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      sent: false
    }
  },
  methods: {
    async sendMyLink() {
      if (!this.$refs.form.validate()) {
        return
      }
      await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/profile`,
        JSON.stringify({ email: this.email }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          responseType: 'text'
        }
      )
      this.sent = true
    }
  },
  mounted() {
    document.title = 'Профиль пользователя сайта Software-Testing.Ru'
  }
}
</script>

<style lang="scss">
</style>
